<!-- 弹框 -->
<template>
  <div class="header-dialog-container">
    <el-dialog
      v-dialogDrag
      :title="dialogTit"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      append-to-body
    >
      <div>
        <!-- 条件 -->
        <div class="common-screen-container">
          <div class="common-input-container" v-for="(item, i) in paramsTit" :key="i">
            <span>{{ item.name }}:</span>
            <el-input
              v-if="item.type == 'input'"
              v-model="headerParams[item.modelKey]"
              placeholder="请输入内容"
              class="common-screen-input"
              clearable
            ></el-input>
            <el-select
              v-else-if="item.type == 'select'&&item.modelKey != 'customerId'"
              class="common-screen-input"
              @change="commonFun()"
              v-model="headerParams[item.modelKey]"
              placeholder="请选择"
              filterable
              :clearable="item.modelKey == 'companyId' ? false:true"
            >
              <el-option
                v-for="item2 in item.selOpt"
                :key="item2.id"
                :label="item2.name"
                :value="item2.id"
              >
              </el-option>
            </el-select>
            <!-- 搜索客户下拉 -->
            <el-select
              v-else-if="item.modelKey == 'customerId'"
              class="common-screen-input"
              @change="commonFun()"
              v-model="headerParams.customerId"
              placeholder="请搜索客户"
              filterable
              clearable
              remote
              :remote-method="(val)=>{remoteMethod(val, 'custList')}"
            >
              <el-option
                v-for="item2 in finishedList"
                :key="item2.id"
                :label="item2.name"
                :value="item2.id"
              >
              </el-option>
            </el-select>
          </div>
          <el-button
            v-show="paramsTit.length"
            class="common-screen-btn"
            type="primary"
            @click="
              () => {
                getHeaderList();
              }
            "
            >查 询</el-button
          >
          <el-button
            v-show="paramsTit.length"
            class="common-screen-btn"
            plain
            @click="() => resetBtn()"
            >重 置</el-button
          >
        </div>
        <!-- 列表 -->
        <el-table  v-loading="loading" element-loading-text="数据较多，拼命加载中..." :data="headerTableData" style="width: 100%">
          <el-table-column
            v-for="(item, i) in childColName"
            :key="i"
            :label="item.name"
            :formatter="tableColumn"
          >
            <template slot-scope="scope">
              <span v-if="item.colProp && item.colProp.length == 2"
                ><span>{{ scope.row[item.colProp[0]] }}</span
                ><br />
                <span>{{ scope.row[item.colProp[1]] }}</span></span
              >
              <span v-else>
                <span
                  v-if="scope.row[item.colProp[0]] == 'servicing'"
                  class="common-tableStatus-green"
                  >正在服务</span
                >
                <span
                  v-else-if="scope.row[item.colProp[0]] == 'stop'"
                  class="common-tableStatus-red"
                  >服务终止</span
                >
                <span
                  v-else-if="item.colProp[0]=='state' && scope.row[item.colProp[0]] == 1"
                  class="common-tableStatus-green"
                  >正在服务</span
                >
                <span
                  v-else-if="item.colProp[0]=='state' && scope.row[item.colProp[0]] == 2"
                  class="common-tableStatus-blue"
                  >待服务</span
                >
                <span
                  v-else-if="item.colProp[0]=='state'&&scope.row[item.colProp[0]] == 0"
                  class="common-tableStatus-red"
                  >服务终止</span
                >
                <span class="table-btn" @click="() => { routerFun(scope.row, item.url, item.paramsArr) }" v-else-if="item.url">{{ scope.row[item.colProp[0]] || "--" }}</span>
                <span v-else>{{ scope.row[item.colProp[0]] || "--" }}</span>
              </span>
            </template>
          </el-table-column>
        </el-table>
        <br/>
        <el-pagination
          @size-change="(e) => pageFun(e, 'pageSize')"
          @current-change="(e) => pageFun(e)"
          style="text-align: center"
          :page-sizes="pagination.pageSizes"
          :page-size="headerParams.pageSize"
          :current-page="headerParams.pageNum"
          layout="total, prev, pager, next"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用

import { Config, tableColumn } from "../../utils/index.js";
import { getCustList } from "../../service/common.js";

export default {
  name: "",
  components: {},
  props: [
    "methodFuc",
    "comId",
    "dialogTit",
    "dialogVisible",
    "headerType",
    "paramsTit",
    "childColName",
  ],
  data() {
    return {
      Config,
      tableColumn,
      headerParams: {
        pageNum: 1,
        pageSize: 10,
      },
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      // loading: this.loading2,
      loading: false,
      headerTableData: [],
      finishedList: [],
      startEndDate: []
    };
  },
  created() {},
  mounted() {},
  methods: {
    routerFun (row, url, paramsArr) { // 弹窗跳转
      let newArr = []
      paramsArr.map((item) => {
        newArr.push(`${item['urlKey']}=${row[item['paramsKey']]}`)
      })
      let routeUrl = url + '?' + newArr.join('&')
      window.open(routeUrl)
    },
    handleClose() {
      this.$emit("handleClose");
      this.headerTableData = [];
      this.resetBtn("noupdate");
    },
    commonFun() {
      this.headerParams.pageSize = 10;
      this.headerParams.pageNum = 1;
      this.getHeaderList();
    },
    async getHeaderList(a,comId, dateArr) {
      this.loading = true;
      if(comId || comId == 0){
        this.headerParams.companyId = comId;
      }
      if (dateArr) {
        this.startEndDate = dateArr
        this.headerParams.startDate = dateArr[0];
        this.headerParams.endDate = dateArr[1];
      }
      let params = { ...this.headerParams };
      
      if (a) {
        params.type = a;
      } else {
        params.type = this.headerType;
      }
      const { data } = await this.methodFuc(params);

      if (data && data.list && data.list.length) {
        this.headerTableData = data.list;
        this.pagination.total = data.total;
        this.pagination.pageSize = data.size;
      } else {
        this.headerTableData = [];
        this.pagination.total = 0;
        // this.pagination.pageSize = data.size;
      }
      this.loading = false;
    },
    resetBtn(type) {
      this.headerParams = {
        pageNum: 1,
        pageSize: 10,
      };
      if(this.comId || this.comId == 0){
        this.headerParams.companyId = Number(this.comId)
      }
      if (this.dateArr && this.dateArr.length) {
        this.headerParams.startDate = this.dateArr[0];
        this.headerParams.endDate = this.dateArr[1];
      }
      // 判断获取
      if (!type) {
        this.getHeaderList();
      }
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.headerParams.pageSize = e;
      } else {
        this.headerParams.pageNum = e;
      }
      this.getHeaderList();
    },
    remoteMethod (val, name) {
      if (name == 'custList') {
        this.getCustList(val)
      }
    },
    async getCustList(name) {
      // 所属客户下拉
      let resData = (await getCustList({customerName: name,companyId: this.comId})).data;
      this.finishedList = resData;
    },
  },
};
</script>

<style lang="less" scoped>
.header-dialog-container {
  padding-top: 1px;
}
.table-btn {
  color: #1890ff;
  cursor: pointer;
}
</style>
